<template>
  <div class="Box">
    <div class="leftBox">
      <textTitle text="联系我们" />
      <div class="myBox">
        <div class="introduce">
          <span>电话：13121112777</span>
          <span>邮箱：pts@yjyculture.com</span>
          <p>地址：北京市北京市朝阳区建国路93号CBD万达广场6号楼305</p>
        </div>
        <!-- <div class="RcCodeBox">
         
          <img src="../../assets/img/qrCode/home.png" class="RcCode">
          <p>关注公众</p>
        </div> -->
      </div>
    </div>
    <sidebar />
  </div>
</template>
<script>
import textTitle from "@/components/headTail/textTitle";
import sidebar from "@/components/sidebar/sidebar";
export default {
  components: {
    textTitle,
    sidebar,
  },
};
</script>
<style lang="scss" scoped>
.myBox {
  overflow: hidden;
  margin-top: 30px;
}
.introduce {
  width: 60%;
  margin-left: 30px;
  float: left;
  span{
      display: block;
      padding: 5px 0   ;
  }
  p{
      margin-top: 15px;
  }
}
.RcCodeBox {
  float: left;
  width: 140px;
  padding: 0 40px; 
  .RcCode {
    height: 140px;

    border: rgb(238, 238, 238) 1px solid;
  }
  p {
    padding: 20px 0;
    text-align: center;
  }
}
</style>